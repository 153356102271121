  $success: #5cb85c;
  $warning: #F7C244;
  $danger: #E35241;
  $white: #fff;
  $default: #8C8C8C;
  $on: #57B056;
  $off: #0071B1;
  $offline: #F7C244;
  $alert: #E73D41;
  .text-danger {
    color: $danger;
    text-decoration: none;
  }
 .text-default{
    color: $default;
    text-decoration: none;
}

.text-success{
  color: $success;
  text-decoration: none;
}