@import './variable';


.float-right{
    float: right;
}

.col-12{
    width: 100%;
}
.row{
    display: flex;
}
.col-6{
    width: 50%;
}

.snackbar-success {
	background: #00bc8c;
	color: $white;
	word-break: break-all;
}

.snackbar-success .mat-simple-snackbar-action {
	color: $white;
}

.snackbar-error {
	background: #e35241;
	color: $white;
	word-break: break-all;
}
.snackbar-error .mat-simple-snackbar-action {
	color: $white;
}

.icon-cursor {
	cursor: pointer;
}

