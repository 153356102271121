/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'src/assets/scss/custom.scss';

body{
  padding: 0;
  margin: 0;
}
.mobile-label {
	display: none;
	font-size: 14px;
	font-weight: 500;
	color: rgba(0, 0, 0, 0.54);
}
.text-center{
	text-align: center !important;
}
.h-scroll {
	overflow-x: scroll;
	// display: grid;
  }
.pt-4 {
	padding-top: 16px;
}

.d-flex{
	display: flex;
}
.ml-auto{
  margin-left:auto;
}

.date-range-field.mat-form-field{
	width: 222px;
}

.date-range-input .mat-form-field-infix{
	width: auto;
}

.mr-2{
	margin-right: 20px !important;
}

.pt-1{
padding-top: 8px;
}
.border-bottom{
border-bottom: 1px solid rgb(204 204 204 / 44%);
padding-bottom: 12px;
margin-bottom: 24px;
}
  
.version-update-info{
	position: absolute;
	background-color: #ffb800;
	color:#673315;
	width: 100vw;
	height: 7vh;
	top: 0;
	left: 0;
	margin: unset !important;
	border-radius: 0px !important;
	min-width: 100% !important;
	.mat-simple-snackbar {
	  justify-content: center;
	  span {
		color:#673315;
		font-size: 18px;
	  }
	}
  }