.add-user-dialog{
    width: 600px;
    
    h2{
        display: inline-block;
        letter-spacing: 0px;
    }
    .mat-form-field{
        width: 100%;
    }
}
.change-pwd-dialog{
    width: 690px;
    h2{
        display: inline-block;
        letter-spacing: 0px;
    }
    .mat-form-field{
        width: 100%;
    }
    .error-desc{
        padding-left: 15px;
    }
}

.my-profile-dialog{
    width: 540px;
    h2{
        display: inline-block;
        letter-spacing: 0px;
    }
    .mat-form-field{
        width: 100%;
    }
    .error-desc{
        padding-left: 15px;
    }
}